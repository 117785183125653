import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import "rxjs/add/operator/map";
import 'rxjs/add/operator/toPromise';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient
  ) { }

  private getUrl(url: string) {
    url = environment.base_url + url;
    return url;
  }

  private getHttpHeaders(options: HttpInputData) {
    let headers = {};
    if (options) {
      headers = { headers: options.headers, params: options.params }
    }
    return headers;
  }

  public get(endpoint: string, options?: HttpInputData): Observable<any> {
    return this.http.get(this.getUrl(endpoint), this.getHttpHeaders(options));
  }

  public post(endpoint: string, payload: any, options?: HttpInputData): Observable<any> {
    return this.http.post(this.getUrl(endpoint), payload, this.getHttpHeaders(options));
  }

  public put(endpoint: string, payload: any, options?: HttpInputData): Observable<any> {
    return this.http.put(this.getUrl(endpoint), payload, this.getHttpHeaders(options));
  }

  public delete(endpoint: string, options?: HttpInputData): Observable<any> {
    return this.http.delete(this.getUrl(endpoint), this.getHttpHeaders(options));
  }

  public downloadcsv(endpoint: string, options?: HttpInputData): Observable<Blob> {
    return this.http.get(this.getUrl(endpoint), {
      ...this.getHttpHeaders(options),
      responseType: 'blob', // This tells HttpClient to expect a blob as the response type.
    });
  }
}

export class HttpInputData {
  headers: HttpHeaders = null;
  params: HttpParams = null;
  authentication: boolean = null;
}
