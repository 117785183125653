import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './gaurd/auth/auth.guard';
import { StorageKeys } from './services/storage/storage-keys';

class RedirectUrl {
  static getRedirectUrl() {
    const savedData = JSON.parse(JSON.parse(localStorage.getItem(StorageKeys.userData)));
    if (savedData) {
      if (savedData.userData && savedData.userData.role == 'Content Approver') {
        return "/admin/picture-approval";
      } else {
        return "/admin/dashboard";
      }
    } else {
      return "/login";
    }
  }
}

const routes: Routes = [
  { path: '', redirectTo: RedirectUrl.getRedirectUrl(), pathMatch: 'full' },
  { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule) },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: RedirectUrl.getRedirectUrl(), pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
