import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageKeys } from 'src/app/services/storage/storage-keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private storage: StorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const savedData = this.storage.getSavedData();
    if (savedData && savedData[StorageKeys.userData]) {
      if ((route.routeConfig.path.includes("login") || route.routeConfig.path.includes("forgot-password"))) {
        this.router.navigate(['/admin/dashboard']);
        return false;
      } else {
        if (savedData[StorageKeys.userData].userData && savedData[StorageKeys.userData].userData.role == 'Content Approver') {
          if (state.url.includes('picture-approval')) {
            return true;
          } else if (state.url.includes('dashboard')) {
            this.router.navigate(['/admin/picture-approval']);
            return false;
          } else {
            this.router.navigate(['/admin/picture-approval']);
            // alert('You as a Content Approver do not have access of this section!');
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      if (route.routeConfig.path.includes("admin")) {
        this.router.navigate(['login']);
        return false;
      } else {
        return true;
      }
    }
  }
}
