export const ApiEndpoints = {
    login: "admin/login",
    logout: "admin/logout",

    // User related API
    usersList: "admin/users",
    changeUserStatus: (user_id: string) => { return `admin/user/change-status/${user_id}` },
    deleteUser: (user_id: string) => { return `admin/user/${user_id}` },
    userDetail: (user_id: string) => { return `admin/user/${user_id}` },
    approachGraph: (user_id: string) => { return `admin/user/approach/${user_id}` },
    updateUser: (user_id: string) => { return `admin/user/${user_id}` },
    profilePics: "admin/profile-pics",
    rateProfilePic: (user_id: string) => { return `admin/user/profile-pic/rating/${user_id}` },
    transactionListForUser: (user_id: string) => { return `admin/user/transaction/${user_id}` },
    userDownloadstatus:'admin/user-csv-download-status',
    usercsvreport:'admin/user-csv-report',
    userReport: (user_id: string) => { return `admin/reports?userId=` + user_id },
    // END: user related API

    changePassword: "admin/change-password",
    changeConentApproverPassword: (user_id: string) => { return `admin/set-password/${user_id}` },
    forgotPassword: "admin/forgot-password",
    resetPassword: "admin/reset-password",

    // app setting APIs

    // GET
    ethnicityList: "admin/ethnicity",
    otherList: "admin/other",
    genderList: "admin/gender",
    subGenderList: `admin/gender/sub-gender/`,
    favouritesList: "admin/favourites",
    preferencesList: "admin/preferences",
    covidStatusList: "admin/covid-status",
    stdStatusList: "admin/std-status",
    filterList: "admin/filter",
    healthStatusList: "admin/monkeypox-status",
    monkeypoxStatusList: "admin/monkeypox-status",

    // ADD
    gender: "admin/gender",
    subGender: `admin/gender/sub-gender/`,
    favourites: "admin/favourites",
    ethnicity: "admin/ethnicity",
    other: "admin/other",
    preferences: "admin/preferences",
    covidStatus: "admin/covid-status",
    stdStatus: "admin/std-status",
    filter: "admin/filter",
    monkeypoxStatus: "admin/monkeypox-status",

    // DELETE
    delete_gender: (id: string) => { return `admin/gender/${id}` },
    delete_subGender: (gender_id: string) => { return `admin/gender/sub-gender/${gender_id}?subGenderId=` },
    delete_favourites: (id: string) => { return `admin/favourites/${id}` },
    delete_ethnicity: (id: string) => { return `admin/ethnicity/${id}` },
    delete_other: (id: string) => { return `admin/other/${id}` },
    delete_preferences: (id: string) => { return `admin/preferences/${id}` },
    delete_covidStatus: (id: string) => { return `admin/covid-status/${id}` },
    delete_stdStatus: (id: string) => { return `admin/std-status/${id}` },
    delete_filter: (id: string) => { return `admin/filter/${id}` },
    delete_monkeypoxStatus: (id: string) => { return `admin/monkeypox-status/${id}` },
    delete_smokingStatus: (id: string) => { return `admin/smoking/${id}` },
    delete_drinkingStatus: (id: string) => { return `admin/drinking/${id}` },
    delete_drugsStatus: (id: string) => { return `admin/drugs/${id}` },

    // UPDATE
    edit_ethnicity: (id: string) => { return `admin/ethnicity/${id}` },
    edit_other: (id: string) => { return `admin/other/${id}` },
    edit_gender: (id: string) => { return `admin/gender/${id}` },
    edit_subGender: (gender_id: string) => { return `admin/gender/sub-gender/${gender_id}` },
    edit_favourites: (id: string) => { return `admin/favourites/${id}` },
    edit_preferences: (id: string) => { return `admin/preferences/${id}` },
    edit_covidStatus: (id: string) => { return `admin/covid-status/${id}` },
    edit_stdStatus: (id: string) => { return `admin/std-status/${id}` },
    edit_filter: (id: string) => { return `admin/filter/${id}` },
    edit_monkeypoxStatus: (id: string) => { return `admin/monkeypox-status/${id}` },
    edit_smokingStatus: (id: string) => { return `admin/smoking/${id}` },
    edit_drinkingStatus: (id: string) => { return `admin/drinking/${id}` },
    edit_drugsStatus: (id: string) => { return `admin/drugs/${id}` },

    // change setting name
    changeSettingName: "admin/system-setting",

    // Image Approval
    getImageApprovalThreshold: "admin/image/threshold",
    updateImageApprovalThreshold: "admin/image/threshold",
    thresholdguideline: "admin/image/threshold-guideline",

    // Rating Guidelines Image
    uploadthresholdimage: "admin/image/upload-threshold-image",
    // deletethresholdimage: "admin/image/delete-threshold-image",
    deletethresholdimage: (rating: string, imgUrl: string) => { return `admin/image/delete-threshold-image?rating=` + rating + `&imgUrl=` + imgUrl },
    // END: app setting APIs


    banProfilePic: (user_id: string) => { return `admin/user/profile-pic/${user_id}` },

    dashboard: "admin/dashboard",

    sendNotification: "admin/notification",

    notificationList: "admin/notification",

    // START: Administrators
    "adminList": "admin/list",
    "adminAdd": "admin/add",
    "editAdmin": (id: string) => { return `admin/edit/${id}` },
    "deleteAdmin": (id: string) => { return `admin/delete/${id}` },
    // END: Administrators 

    "chatscreen": "admin/chat/profanity",

    //picture approval
    "pictureapproval": "admin/unrated-profile-pic",

    //resion
    "addupdateReason": "admin/reason",

    // Report
    "report": "admin/reports",
    "deletereport": "admin/report",
    resolutionstatus: (user_id: string) => { return `admin/reporting-review/${user_id}` },

    //Coupon
    "addcoupon": "admin/save-coupon",
    "getcoupon": "admin/coupons",
    "deleteupdatecoupon": "admin/coupons",
    changeCouponStatus: (couponid: string) => { return `admin/coupon/${couponid}` },

    // Blocked number
    blockedNumber: "admin/block-number",
    addBlockedNumber: "admin/block-number",
    updateBlockedNumber: (id: string) => { return "admin/block-number/" + id },
    deleteBlockedNumber: (id: string) => { return "admin/block-number/" + id },

    // nearby-member-report,suspended or deleted user list

    "nearbymenber": "admin/nearby-member-report-list",
    "usersuspended": "admin/user-suspended-list",
    "deleteuserlist": "admin/user-deleted-list",
    "appversionlist": "admin/app-version-list",
    "addversion": "admin/app-version-card",
    "exportfile": "admin/nearby-member-excel-report",

    //Device details

    deviceuserlist: 'admin/device-block',
    deviceaccount: 'admin/get-device-account-block-list',

    // onlineUsers
    onlineusers: 'admin/online-search-timer',
    onlineNowList: 'admin/online-now-timer',

    // onlineIndicator
    onlinetimer: 'admin/online-timer',
    onlineTimerList: 'admin/online-timer',

    //Feed_Back
    feedback:"admin/feedback-rating",
    feedbackDownload:"admin/feedback-rating?download=csv",
    // Geocountry-location
    "countrylist" : "admin/country-list",

    //  My vices List
    myVicesStatusList: "admin/smoking",
    smokingStatusList: "admin/smoking",
    drinkingStatusList: "admin/drinking",
    drugsStatusList: "admin/drugs",
    //  Add vices List
    smokingStatus: "admin/smoking",
    drinkingStatus: "admin/drinking",
    drugsStatus: "admin/drugs",

}