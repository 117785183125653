import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpoints } from '../http/Api-endpoints';
import { HttpInputData, HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpService
  ) { }

  login(payload: Login) {
    return new Promise((resolve, reject) => {
      this.http.post(ApiEndpoints.login, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      this.http.get(ApiEndpoints.logout).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      )
    });
  }

  changePassword(payload: ChangePassword) {
    return new Promise((resolve, reject) => {
      this.http.put(ApiEndpoints.changePassword, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  changeContentApproverPassword(id, payload: ChangePassword) {
    return new Promise((resolve, reject) => {
      this.http.put(ApiEndpoints.changeConentApproverPassword(id), payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  forgotPassword(email: string) {
    // let httpData = new HttpInputData();
    // let httpHeader = new HttpHeaders();
    // httpHeader = httpHeader.set('')
    return new Promise((resolve, reject) => {
      this.http.post(ApiEndpoints.forgotPassword, { "email": email }).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  resetPassword(password: string, token: string) {
    return new Promise((resolve, reject) => {
      this.http.post(ApiEndpoints.resetPassword, {
        "password": password,
        "confirmPassword": password,
        "token": token
      }).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}

export class Login {
  email: string;
  password: string;

  constructor(payload: {
    email: string;
    password: string;
  }) {
    this.email = payload.email;
    this.password = payload.password;
  }
}

export class ChangePassword {
  newPassword: string;
  confirmPassword: string;

  constructor(payload: {
    newPassword: string;
    confirmPassword: string;
  }) {
    this.newPassword = payload.newPassword;
    this.confirmPassword = payload.confirmPassword;
  }
}
