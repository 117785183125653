import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { iif, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StorageService } from '../../storage/storage.service';
import { StorageKeys } from '../../storage/storage-keys';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private storage: StorageService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let headers = {};
    let savedData = this.storage.getSavedData();
    if (savedData && savedData[StorageKeys.token]) {
      let token = savedData[StorageKeys.token].token;
      headers['Authorization'] = token;
    }
    const interceptedRequest = request.clone({
      "headers": new HttpHeaders(headers)
    });
    return next.handle(interceptedRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {

        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status == 401) {
              this.storage.clearStorageForLogout().then(
                () => {
                  this.router.navigate(['/login']);
                }
              );
            }
          }
        }
      )
    )
  }
}
