<div class="auth-body-bg">
  <div class="home-btn d-none d-sm-block">
    <a><i class="mdi mdi-home-variant h2 text-white"></i></a>
  </div>
  <div>
    <div class="container-fluid p-0">
      <div class="row no-gutters">
        <div class="col-lg-4">
          <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
            <div class="w-100">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div>
                    <div class="text-center">
                      <div>
                        <a class="logo"><img src="assets/images/logo-dark.svg" height="50" alt="logo"></a>
                      </div>
                      <h4 class="font-size-18 mt-3">Welcome to Login</h4>
                    </div>

                    <div class="p-2 mt-3">
                      <form class="form-horizontal" [formGroup]="loginForm">

                        <div class="form-group auth-form-group-custom mb-4">
                          <i class="ri-user-2-line auti-custom-input-icon"></i>
                          <label for="username">Username</label>
                          <input type="text" class="form-control" id="username" formControlName="email" (keydown.space)="$event.preventDefault()"
                            placeholder="Enter username">
                          <p class="text-danger" *ngIf="(!loginForm?.controls['email']?.value) && enterUserName">Enter
                            username to reset
                            your password!</p>
                        </div>

                        <div class="form-group auth-form-group-custom mb-4">
                          <i class="ri-lock-2-line auti-custom-input-icon"></i>
                          <label for="userpassword">Password</label>
                          <input type="password" autocomplete="off" class="form-control" maxlength="16" (keydown.space)="$event.preventDefault()"
                            formControlName="password" id="userpassword" placeholder="Enter password">
                        </div>

                        <div class="custom-control custom-checkbox" style="display: none;">
                          <input type="checkbox" class="custom-control-input" id="customControlInline">
                          <label class="custom-control-label" for="customControlInline">Remember me</label>
                        </div>

                        <div class="mt-4 text-center">
                          <button class="btn btn-primary w-md waves-effect waves-light" [disabled]="loginForm?.invalid"
                            (click)="doLogin()" type="submit">Log In</button>
                        </div>

                        <div class="mt-4 text-center">
                          <a style="cursor: pointer;" class="text-muted" (click)="forgotPassword()"><i
                              class="mdi mdi-lock mr-1"></i>
                            Forgot
                            your password?</a>
                          <div *ngIf="forgotPasswordMailSent" class="alert alert-success p-2 mt-2 mb-0 font-size-12"
                            role="alert">
                            An email has been sent to the registered email id for password reset.
                          </div>
                        </div>
                      </form>
                    </div>

                    <div class="mt-3 text-center  copyright">
                      <p>© 2021 Headero Crafted with <i class="mdi mdi-heart text-danger"></i> by <a
                          href="https://greychaindesign.com/">Grey Chain</a></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="authentication-bg">
            <div class="bg-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
