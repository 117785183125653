import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiEndpoints } from '../http/Api-endpoints';
import { HttpInputData, HttpService } from '../http/http.service';
import { BlockNumberPayload } from './common.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private httpWrapper: HttpService
  ) { }

  presentSpinner() {
    this.spinner.show();
  }

  dismissSpinner() {
    this.spinner.hide();
  }

  calculateEntries(list: Array<any>, current_page: number, limit: number, totalResult: number): Entries {
    if(!list || !list.length) {
      list = [];
    }
    if(!totalResult) {
      totalResult = list.length;
    }
    if(!limit) {
      limit = list.length;
    }
    if (list.length === totalResult) {
      let pages = {
        1: []
      };
      let arr = [];
      let page = 1;
      for (let i = 0; i < list.length; i++) {
        arr.push(list[i]);
        if (arr.length == limit) {
          pages[page] = arr;
          page += 1;
          arr = [];
        } else {
          if (i == list.length - 1) {
            pages[page] = arr;
            page += 1;
            arr = [];
          }
        }
      }
      list = pages[current_page];
    }
    return {
      from: limit * (current_page - 1) + 0 + 1,
      to: limit * (current_page - 1) + (list.length - 1) + 1,
      total: totalResult,
    };
  }

  presentSuccessToastMessage(title: string, message: string) {
    this.toastrService.success(message, title, {
      // positionClass: "toast-top-left",
      timeOut: 2000
    });
  }

  presentErrorToastMessage(title: string, message: string) {
    this.toastrService.error(message, title, {
      // positionClass: "toast-top-left",
      timeOut: 2000
    });
  }

  getDashboardData(timePeriod: string) {
    let httpInput = new HttpInputData();
    let httpParams = new HttpParams();
    if (timePeriod) {
      httpParams = httpParams.set('period', timePeriod);
    }
    httpInput.params = httpParams
    return this.httpWrapper.get(ApiEndpoints.dashboard, httpInput).map(
      (res) => {
        return res;
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      )
  }

  // ------------------------ Block Number api ----------------------
  getBlockedNumbers(query: { page?: number; limit?: number; }) {
    let httpInputData = new HttpInputData();
    let httpParams = new HttpParams();
    if(query) {
      Object.keys(query).forEach(
        (key) => {
          if(query[key]) {
            httpParams = httpParams.set(key, query[key]);
          }
        }
      );
    }
    httpInputData.params = httpParams;
    return this.httpWrapper.get(ApiEndpoints.blockedNumber, httpInputData).map(
      (res) => {
        return res;
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      )
  }

  addBlockedNumbers(payload: BlockNumberPayload) {
    return this.httpWrapper.post(ApiEndpoints.addBlockedNumber, payload).map(
      (res) => {
        return res;
      }
    ).toPromise()
      .catch(
        ({error}) => {
          if(error && error.message) {
            this.presentErrorToastMessage('', error.message)
          } else {
            this.presentErrorToastMessage('', 'Something went wrong. Please try again or later.')
          }
        }
      )
  }

  updateBlockedNumbers(rangeNumberId: string, payload: BlockNumberPayload) {
    return this.httpWrapper.put(ApiEndpoints.updateBlockedNumber(rangeNumberId), payload).map(
      (res) => {
        return res;
      }
    ).toPromise()
      .catch(
        ({error}) => {
          if(error && error.message) {
            this.presentErrorToastMessage('', error.message)
          } else {
            this.presentErrorToastMessage('', 'Something went wrong. Please try again or later.')
          }
        }
      )
  }

  deleteBlockedNumbers(rangeNumberId: string) {
    return this.httpWrapper.delete(ApiEndpoints.updateBlockedNumber(rangeNumberId)).map(
      (res) => {
        return res;
      }
    ).toPromise()
      .catch(
        ({error}) => {
          if(error && error.message) {
            this.presentErrorToastMessage('', error.message)
          } else {
            this.presentErrorToastMessage('', 'Something went wrong. Please try again or later.')
          }
        }
      )
  }

  getCountryList() {
    return this.httpWrapper.get(ApiEndpoints.countrylist).map(
      (res) => {
        return res;
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      )
  }

  addCountryList(payload) {
    return this.httpWrapper.post(ApiEndpoints.countrylist,payload).map(
      (res) => {
        return res;
      }
    ).toPromise()
      .catch(
        (error) => {
          return error;
        }
      )
  }
}

export interface Entries {
  from: any;
  to: any;
  total: any;
}
