import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, Login } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { StorageKeys } from 'src/app/services/storage/storage-keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  enterUserName: boolean;
  forgotPasswordMailSent: boolean;

  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private storage: StorageService,
    private router: Router
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required])),
      password: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(16)]))
    });
  }

  ngOnInit(): void {
  }

  doLogin() {
    this.commonService.presentSpinner();
    this.authService.login(new Login(this.loginForm.value)).then(
      (res: any) => {
        if (res) {
          this.storage.set(StorageKeys.token, { token: res.token });
          this.storage.set(StorageKeys.userData, { userData: res.admindata });
          if (res.admindata.role == "Content Approver") {
            this.router.navigate(["/admin/picture-approval"]);
          } else {
            this.router.navigate(['/admin/dashboard']);
          }
        }
      }
    ).catch(
      (error: HttpErrorResponse) => {
        if (error.error.responseCode == 422) {
          this.commonService.presentErrorToastMessage('', "Invalid credentials!");
        } else {
          this.commonService.presentErrorToastMessage('', "Couldn't login. Please try again.");
        }
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    )
  }

  forgotPassword() {
    if (!this.loginForm.value.email) {
      this.enterUserName = true
      return;
    } else {
      this.enterUserName = false;
    }
    this.commonService.presentSpinner();
    this.authService.forgotPassword(this.loginForm.value.email).then(
      (res) => {
        if (res) {
          // this.router.navigate(['/forgot-password'], { state: { token: res.token } })
          this.forgotPasswordMailSent = true;
        } else {
          this.forgotPasswordMailSent = false;
        }
      }
    ).catch(
      (error) => {
        this.forgotPasswordMailSent = false;
      }
    ).finally(
      () => {
        this.commonService.dismissSpinner();
      }
    )
  }

}
